<template>
  <v-container>
    <v-dialog
        v-model="dialog"
        persistent
        :max-width="900"
    >
      <v-card class="text-h4 text-center  ">
        <v-card-title class="text-h4 d-inline-flex pb-8 pt-8" id="title">
         <h4 class="d-inline-flex" style="font-family: 'Brown Sugar', sans-serif">
           Herzlich Willkommen, {{ userName }}!
         </h4>
        </v-card-title>
        <v-card-text>
            <v-container>
              <v-row class=" align-center">
                <v-col cols="12" md="4" class="text-center ">
                  <v-img
                      :src="require('@/assets/images/drawSVGs/welcome.svg')"
                      max-width="150px"
                      class="d-inline-flex"/>
                </v-col>
                <v-col cols="12"  md="8" class="text-start ">
                  <h4>Wir freuen uns, dass du da bist und wünschen dir viel Spaß beim Entdecken der Funktionen und Features von WeddingFlow. Wenn du die Einführungstour erst später starten möchtest, kannst du jederzeit über das <v-icon>mdi mdi-account-question</v-icon>-Symbol oben rechts erneut beginnen.</h4>
                </v-col>
              </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions class="">
          <v-container>
            <v-row class="align-center">
              <v-col cols="12" md="6" class="text-md-end text-center">
                <v-btn
                    color="primary"
                    text
                    @click="startTour"
                    class="d-inline-flex"
                >
                  Einführungstour starten
                </v-btn>
              </v-col>
              <v-col cols="12" md="6"  class="text-md-start text-center">
                <v-btn
                    color="primary"
                    @click="closeDialog"
                    class="d-inline-flex"
                >
                  Selbst entdecken
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
/**
 * Snackbar to show user successful and not successful tries to edit, create or delete a value
 * @displayName Alert
 */
export default {
  name: "WelcomeWidget",
  props: {
    dialog: Boolean,
    userName: String
  },
  methods:{
    closeDialog(){
      this.$emit('hideDialog')
    },
    startTour(){
      this.$emit('startTour')
    }
  }
}
</script>
